import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Share from 'components/elements/Misc/Share'

// Hooks
import usePage from 'hooks/usePage'

// Images
import Location from 'img/location-transparent.inline.svg'
import Clock from 'img/clock-transparent.inline.svg'
import Bars from 'img/bars-transparent.inline.svg'

// Interface
import { TextProps } from 'components/flex/Text/TextShell'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import ButtonSecondary from 'components/elements/Buttons/ButtonSecondary'

const Section = styled.section`
  z-index: 4;
`

const Vacancy = styled.div`
  background-color: ${({ theme }) => theme.color.light};
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);

  @media (min-width: 992px) {
    padding: 3rem 5rem 18rem 5rem;
  }

  @media (max-width: 991.98px) {
    padding: 1.5rem;
  }
`

const Title = styled.h2`
  font-family: ${({ theme }) => theme.font.family.secondary};

  @media (min-width: 992px) {
    font-size: ${({ theme }) => theme.font.size.ultra};
  }
`

const Details = styled.div`
  border-bottom: 2px solid ${({ theme }) => theme.color.secondary};
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-size: ${({ theme }) => theme.font.size.bigger};
    line-height: ${({ theme }) => theme.font.size.bigger};
    margin-bottom: 0.25rem;
  }
`

const TextPost: React.FC<TextProps> = ({ fields }) => {
  const { title, uri }: any = usePage()

  return (
    <Section className="position-relative">
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className={`col-lg-${fields.containerwidth}`}>
            <Vacancy>
              <div className="d-flex flex-wrap justify-content-between align-items-center">
                <ButtonPrimary to="/about-us/news" className="mb-3 mb-lg-0">
                  Back to overview
                </ButtonPrimary>
                <Share title={title} excerpt="" src={uri} />
              </div>
              <Title className="my-5">{title}</Title>
              <Content content={fields.description} />
            </Vacancy>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default TextPost
